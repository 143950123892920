<template>
  <div class="fullheight">
    <spinner v-show="loading" texto="Cargando, por favor aguarde..."></spinner>
    <div class="content-header flex-row flex-row-between" v-if="!loading && evento !== null">
      <h1>Formulario datos de entrega - Evento n° {{ idEvento }}</h1>

      <div class="content-header-buttons flex-row">
        <button class="btn-primary animated" @click="sendForm()">Guardar</button>
      </div>
    </div>

    <div class="content-content scrolleable flex-column flex-column-left flex-column-top" v-if="!loading && evento !== null">
      <form class="form-container fullwidth">
        <div class="flex-row flex-row-left flex-row-nomargin">
          <p class="content-subtit">Datos de entrega</p>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <select v-model="datosEntrega.tipoEvento" id="evento-selected-tipo-evento" name="Tipo de evento" class="form-field form-field-nomargin"
            :class="{'field-complete': datosEntrega.tipoEvento !== ''}">
            <option value="">Elija tipo de evento</option>
              <option v-for="tipoEvento in tiposEventos" :key="tipoEvento.value" :value="tipoEvento.text">
                {{ tipoEvento.text }}
              </option>
            </select>
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
                   for="evento-selected-tipo-evento">Tipo de evento</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <datepicker
              v-model="evento.fechaEvento"
              name="Fecha del evento"
              class="fullwidth"
              placeholder="Elija una fecha"
              type="text"
              :format="DateHelper.formatoFechaAmigable"
              :language="datepickerOptions.language"
              :class="dateClasses(evento.fechaEvento)"
              :input-class="inputeDateClasses(evento.fechaEvento)"
            />
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active">Fecha de evento</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.horaEvento" id="evento-selected-hora-evento" name="Hora de evento" placeholder="Escriba un horario (hh:mm)"
                   class="form-field form-field-nomargin" :class="{'field-complete': !TimeHelper.isEmpty(datosEntrega.horaEvento)}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-hora-evento">Hora de evento</label>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <select v-model="datosEntrega.tipoLugar" id="evento-selected-tipo-lugar" name="Tipo de lugar" class="form-field form-field-nomargin"
            :class="{'field-complete': datosEntrega.tipoLugar !== ''}">
            <option value="">Elija tipo de lugar</option>
              <option v-for="tipoLugar in tiposLugares" :key="tipoLugar.value" :value="tipoLugar.text">
                {{ tipoLugar.text }}
              </option>
            </select>
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
                   for="evento-selected-tipo-lugar">Tipo de lugar</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.direccion" v-validate="'required'" id="evento-selected-direccion" name="Direccion"
                   class="form-field form-field-nomargin" placeholder="Escriba la dirección"
                   :class="{error: errors.has('Direccion'), 'field-complete': valueCompleted(datosEntrega.direccion)}"
                   type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-direccion">Dirección</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.localidad" id="evento-selected-localidad" name="Localidad"
                   class="form-field form-field-nomargin" type="text" placeholder="Escriba la localidad"
                   :class="{'field-complete': valueCompleted(datosEntrega.localidad)}">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-localidad">Localidad</label>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.comienzoEvento" id="evento-selected-comienzo-evento" name="Comienzo del evento" placeholder="Fecha y hora de comienzo del evento"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.comienzoEvento !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-comienzo-evento">Comienzo del evento (fecha y hora)</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.finalizacionEvento" id="evento-selected-finalizacion-evento" name="Finalizacion del evento" placeholder="Duración o finalizacion del evento"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.finalizacionEvento !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active" for="evento-selected-finalizacion-evento">Duracion/finalizacion del evento</label>
          </div>
        </div>

        <div class="flex-row flex-row-left">
          <p class="content-subtit">Disposición mobiliario</p>
        </div>

        <div class="flex-row flex-row-left row-semimargin-left">
          <div class="checkbox-container mr">
            <input type="checkbox" name="Disposicion interior" id="datos-entrega-disposicion-mobiliario-interior"
              v-model="datosEntrega.disposicionMobiliario.interior">
            <label for="datos-entrega-disposicion-mobiliario-interior">EN INTERIOR</label>
          </div>
          <div class="checkbox-container mr">
            <input type="checkbox" name="Disposicion carpa" id="datos-entrega-disposicion-mobiliario-carpa"
              v-model="datosEntrega.disposicionMobiliario.carpa">
            <label for="datos-entrega-disposicion-mobiliario-carpa">CARPA</label>
          </div>
          <div class="checkbox-container mr">
            <input type="checkbox" name="Disposicion interperie" id="datos-entrega-disposicion-mobiliario-interperie"
              v-model="datosEntrega.disposicionMobiliario.interperie">
            <label for="datos-entrega-disposicion-mobiliario-interperie">INTERPERIE</label>
          </div>
          <div class="checkbox-container">
            <input type="checkbox" name="Disposicion semicubierto" id="datos-entrega-disposicion-mobiliario-semicubierto"
              v-model="datosEntrega.disposicionMobiliario.semicubierto">
            <label for="datos-entrega-disposicion-mobiliario-semicubierto">SEMICUBIERTO</label>
          </div>
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.tipoEdificacion" id="datos-entrega-tipo-edificacion" name="Tipo de edificacion"
              placeholder="Tipo de edificacion y condiciones de acceso"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.tipoEdificacion !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-tipo-edificacion">Tipo de edificación y condiciones de acceso</label>
          </div>

        </div>
        <p class="flex-row flex-row-left row-semimargin-left">Los muebles no pueden quedar a la interperie los días de lluvia.</p>

        <div class="flex-row flex-row-left">
          <p class="content-subtit">Fecha y hora entrega y retiro</p>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top form-field-indicador-container flex-eqw">
            <datepicker
              v-model="datosEntrega.fechaEntrega"
              name="Fecha de entrega"
              class="fullwidth"
              placeholder="Elija una fecha"
              type="text"
              :format="DateHelper.formatoFechaAmigable"
              :language="datepickerOptions.language"
              :class="dateClasses(datosEntrega.fechaEntrega)"
              :input-class="inputeDateClasses(datosEntrega.fechaEntrega)"
            />
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active">Fecha de entrega</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.rangoHorarioEntrega" id="datos-entrega-rango-horario-entrega" name="Rango horario de entrega"
              placeholder="Entre las 08 y las 10hs"
                   class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.rangoHorarioEntrega !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-rango-horario-entrega">Rango horario de entrega</label>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top form-field-indicador-container flex-eqw">
            <datepicker
              v-model="datosEntrega.fechaRetiro"
              name="Fecha de retiro"
              class="fullwidth"
              placeholder="Elija una fecha"
              type="text"
              :format="DateHelper.formatoFechaAmigable"
              :language="datepickerOptions.language"
              :class="dateClasses(datosEntrega.fechaRetiro)"
              :input-class="inputeDateClasses(datosEntrega.fechaRetiro)"
            />
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active">Fecha de retiro</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.rangoHorarioRetiro" id="datos-entrega-rango-horario-entrega" name="Rango horario de retiro"
              placeholder="Entre las 08 y las 10hs"
                   class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.rangoHorarioRetiro !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-rango-horario-entrega">Rango horario de retiro</label>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <textarea v-model="datosEntrega.observacionesEntregaRetiro" id="datos-entrega-observaciones-entrega-retiro" name="Observaciones entrega retiro"
              placeholder="Observaciones entrega/retiro"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.observacionesEntregaRetiro !== ''}" />
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-observaciones-entrega-retiro">Observaciones de entrega/retiro</label>
          </div>
        </div>

        <div class="flex-row flex-row-left">
          <p class="content-subtit">Datos de contacto</p>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.contactoCoordinacion" id="datos-entrega-contacto-coordinacion" name="Contacto coordinacion"
              placeholder="Nombre y apellido contacto coordinacion"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.contactoCoordinacion !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-contacto-coordinacion">Contacto coordinacion</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.telefonoCoordinacion" id="datos-entrega-telefono-coordinacion" name="Telefono coordinacion"
              placeholder="Telefono coordinacion"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.telefonoCoordinacion !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-telefono-coordinacion">Telefono coordinacion</label>
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.contactoRecepcion" id="datos-entrega-contacto-recepcion" name="Contacto recepcion"
              placeholder="Nombre y apellido contacto recepcion"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.contactoRecepcion !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-contacto-recepcion">Contacto recepcion</label>
          </div>

          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.telefonoRecepcion" id="datos-entrega-telefono-recepcion" name="Telefono recepcion"
              placeholder="Telefono recepcion"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.telefonoRecepcion !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-telefono-recepcion">Telefono recepcion</label>
          </div>
        </div>

        <div class="flex-row flex-row-left flex-row-nomargin">
          <p class="content-subtit">Datos adicionales</p>
        </div>

        <div class="flex-row">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.tonoAlmohadones" id="datos-entrega-tono-almohadones" name="Telefono recepcion"
              placeholder="Tono almohadones o recepcion (si corresponde)"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.tonoAlmohadones !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-tono-almohadones">Tono almohadones/decoracion</label>
          </div>
          <div class="flex-column flex-top flex-eqw form-field-indicador-container flex-eqw">
            <input v-model="datosEntrega.segurosAP" id="datos-entrega-seguros-ap" name="Telefono recepcion"
              placeholder="Requerimientos de seguros AP"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.segurosAP !== ''}" type="text">
            <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-seguros-ap">Requerimientos de seguros AP</label>
          </div>
        </div>

        <div class="flex-row flex-row-left flex-row-nomargin">
          <p class="content-subtit">Plano de armado</p>
        </div>
        <p class="flex-row flex-row-left row-semimargin-left">
          Para mayor seguridad y comodidad adjuntar o enviar por separado plano de armado antes del evento.
          El equipo no realizará el despliegue del mobiliario sin instrucciones precisas previas.
        </p>

        <div class="flex-row">
          <div class="flex-column flex-top flex-column-left form-field-indicador-container flex-eqw">
            <input id="datos-entrega-tono-almohadones" name="Telefono recepcion" @change="handlerPlanoArmadoFile"
              placeholder="Tono almohadones o recepcion (si corresponde)" type="file"
              class="form-field form-field-nomargin" :class="{'field-complete': datosEntrega.planoArmado !== ''}">
            <!-- <label class="form-field-indicador form-field-indicator-floating form-field-indicator-floating-active"
              for="datos-entrega-tono-almohadones">Tono almohadones/decoracion</label> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { EventBus } from '@/event-bus.js'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'
import DateHelper from '@/DateHelper'
import TimeHelper from '@/TimeHelper'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Validation from '@/Validation'
import ConstantsHelper from '@/ConstantsHelper'
import spinner from '@/components/spinner.vue'
const moment = extendMoment(Moment)

export default {
  components: {
    Datepicker,
    spinner
  },
  data () {
    return {
      DateHelper,
      TimeHelper,
      loading: false,
      evento: null,
      idEvento: '',
      fechaEvento: '',
      datosEntrega: {
        tipoEvento: '',
        horaEvento: '',
        tipoLugar: '',
        comienzoEvento: '',
        finalizacionEvento: '',
        tipoEdificacion: '',
        disposicionMobiliario: {
          interior: false,
          carpa: false,
          interperie: false,
          semicubierto: false
        },
        fechaEntrega: '',
        rangoHorarioEntrega: '',
        fechaRetiro: '',
        rangoHorarioRetiro: '',
        observacionesEntregaRetiro: '',
        contactoCoordinacion: '',
        telefonoCoordinacion: '',
        contactoRecepcion: '',
        telefonoRecepcion: '',
        tonoAlmohadones: '',
        segurosAP: '',
        planoArmado: ''
      },
      datepickerOptions: {
        language: es,
        disabledDates: {
          to: moment().subtract(1, 'd').toDate()
        }
      },
      tiposEventos: [
        {
          value: 'corporativo',
          text: 'CORPORATIVO'
        },
        {
          value: 'social-boda',
          text: 'SOCIAL BODA'
        },
        {
          value: 'social-ceremonia-civil',
          text: 'SOCIAL CEREMONIA CIVIL'
        },
        {
          value: 'social-cumpleanos',
          text: 'SOCIAL CUMPLEAÑOS'
        },
        {
          value: 'social-otro',
          text: 'SOCIAL OTRO'
        },
        {
          value: 'feria-expo',
          text: 'FERIA/EXPO'
        }
      ],
      tiposLugares: [
        {
          value: 'SITIO DE EVENTOS',
          text: 'SITIO DE EVENTOS'
        },
        {
          value: 'SUM BARRIO CERRADO',
          text: 'SUM BARRIO CERRADO'
        },
        {
          value: 'PROPIEDAD EN BARRIO CERRADO',
          text: 'PROPIEDAD EN BARRIO CERRADO'
        },
        {
          value: 'SUM EN EDIFICIOS',
          text: 'SUM EN EDIFICIOS'
        },
        {
          value: 'EDIFICIO O CASA PARTICULAR',
          text: 'EDIFICIO O CASA PARTICULAR'
        },
        {
          value: 'CAMPO / QUINTA PRIVADA',
          text: 'CAMPO / QUINTA PRIVADA'
        }
      ]
    }
  },
  methods: {
    dateClasses (fecha) {
      const classes = []
      if (!DateHelper.isEmpty(fecha)) {
        classes.push('field-complete-datepicker')
      }
      return classes
    },
    inputeDateClasses (fecha) {
      const classes = []
      if (!DateHelper.isEmpty(fecha)) {
        classes.push('field-complete')
      }
      /* if (errors.has('Fecha del evento')) {
        classes.push('error')
      } */
      return classes
    },
    valueCompleted (value) {
      return Validation.valueCompleted(value)
    },
    handlerPlanoArmadoFile (evt) {
      let files = evt.target.files
      this.datosEntrega.plano = files
      console.log(this.datosEntrega)
      // var vm = this
      /* for (let i = 0; i < files.length; i++) {
        let validation = new Validation()
        if (validation.validarImagen(files[i])) {
          let reader = new FileReader()
          reader.onload = function (e) {
            // vm.imgUploaded.push(e.target.result)
            // vm.producto.fotos.push({ foto: e.target.result })
          }
          reader.readAsDataURL(files[i])
        } else {
          EventBus.$emit('generateToast', {
            message: 'Archivo',
            type: 'error'
          })
        }
      } */
    },
    getEventoById (id) {
      this.loading = true
      ajax('GET', ConstantsHelper.getURLs().v1 + 'eventos.php?id=' + id + '&all=true')
        .then((response) => {
          if (response.code === 1 && response.data.length > 0) {
            this.evento = response.data[0]
            let fechaFormatoBD = DateHelper.formatoCalendario(this.evento.fechaEvento)
            let dateParts = fechaFormatoBD.split('-')
            this.evento.fechaEvento = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
            this.setDatosEntregaFromEvento(this.evento)

            // const comienzo = this.evento?.datosEntrega?.

            EventBus.$emit('generateToast', {
              message: response.response,
              type: 'correct'
            })
          } else {
            EventBus.$emit('generateToast', {
              message: response.response,
              type: 'error'
            })
          }
          this.loading = false
        })
    },
    setDatosEntregaFromEvento (evento) {
      this.datosEntrega.tipoEvento = evento.datosEntrega.tipoEvento
      this.datosEntrega.horaEvento = evento.datosEntrega.horaEvento
      this.datosEntrega.direccion = evento.datosEntrega.direccion
      this.datosEntrega.localidad = evento.datosEntrega.localidad
      this.datosEntrega.fechaEntrega = evento.datosEntrega.fechaEntrega
      this.datosEntrega.horaEntrega = evento.datosEntrega.horaEntrega
      this.datosEntrega.horaRetiro = evento.datosEntrega.horaRetiro
      this.datosEntrega.rangoHorarioEntrega = evento.datosEntrega.opcionesEntrega
      this.datosEntrega.fechaRetiro = evento.datosEntrega.fechaRetiro
      this.datosEntrega.rangoHorarioRetiro = evento.datosEntrega.opcionesRetiro
      this.datosEntrega.tipoLugar = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Tipo de Lugar')
      this.datosEntrega.comienzoEvento = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Comienzo del evento')
      this.datosEntrega.finalizacionEvento = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Finalizacion del evento')
      const disposicionMobiliarioAux = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Disposicion de mobiliario').toLocaleLowerCase()
      this.datosEntrega.disposicionMobiliario[disposicionMobiliarioAux] = true
      this.datosEntrega.tipoEdificacion = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Tipo de edificacion')
      this.datosEntrega.tonoAlmohadones = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesPedido, 'Tono de almohadones')
      this.datosEntrega.observacionesEntregaRetiro = this.extractFromEventField(evento.datosEntrega.detallesAdicionalesEntrega, 'Observaciones entrega / retiro')
      this.datosEntrega.contactoCoordinacion = evento.datosEntrega.contactoCoordinacion
      this.datosEntrega.telefonoCoordinacion = evento.datosEntrega.telefonoCoordinacion
      this.datosEntrega.contactoRecepcion = evento.datosEntrega.contactoRecepcion
      this.datosEntrega.telefonoRecepcion = evento.datosEntrega.telefonoRecepcion
      this.datosEntrega.segurosAP = evento.datosEntrega.segurosAP || ''
      this.datosEntrega.planoArmado = evento.datosEntrega.planoArmado || ''
    },
    extractFromEventField (text, tag) {
      const regex = new RegExp(`${tag}: (.*?)(?= -|$)`, 'i')
      const resultado = regex.exec(text)
      return resultado ? resultado[1].trim() : null
    },
    sendForm () {
      this.loading = true
      ajax('PUT', ConstantsHelper.getURLs().v1 + 'formulario-datos-entrega2.php', {
        datosEntrega: this.datosEntrega,
        id_evento: this.idEvento,
        action: 'form1'
      })
        .then((response) => {
          console.log(response)
          let type = 'error'
          if (response.status === 1) {
            type = 'correct'
          }
          EventBus.$emit('generateToast', {
            message: response.message,
            type: type
          })
        })
        .catch((error) => {
          EventBus.$emit('generateToast', {
            message: 'Surgio un error!',
            type: 'error'
          })
          console.log('Operacion fallida', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    if (this.$route.query.evento) {
      this.idEvento = this.$route.query.evento
      this.getEventoById(this.idEvento)
    }
  }
}
</script>

<style scoped>
.fullheight {
  height: 100vh;
}

.content-header {
  margin-top: 25px;
}
.content-content {
  min-height: calc(100vh - 40px - 55px);
}

.content-subtit {
  padding: 0;
  margin-left: 15px;
}

.row-semimargin-left {
  padding: 0 2.5vw 0 2.5vw !important;
  text-align: left;
  width: 70%;
}
.row-semimargin-left .checkbox-container.mr {
  margin-right: 2rem;
}
</style>
